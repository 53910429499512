import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { SocketError } from '@wayste/sour-socket';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatServiceAddress, getRouterPath } from '@wayste/utils';
import { SearchHit } from '@elastic/elasticsearch/lib/api/types';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import { addDays, format, isAfter, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import ButtonSelect from '../../../components/ButtonSelect';
import { UIContext } from '../../../contexts';
import { leadTypesEnums, routes } from '../../../utils';
import AdminPanel from './AdminPanel';

const RedPill = ({ text }: { text: string }) => (
    <div className="bg-error-light text-error-dark inline-flex h-6 items-center rounded-full px-2.5 text-xs font-medium">{text}</div>
);

const OrangePill = ({ text }: { text: string }) => (
    <div className="inline-flex h-6 items-center rounded-full bg-[#fef08a] px-2.5 text-xs font-medium text-[#ea580c]">{text}</div>
);

const GreenPill = ({ text }: { text: string }) => (
    <div className="bg-sourgum-green-200 text-sourgum-green-700 inline-flex h-6 items-center rounded-full px-2.5 text-xs font-medium">
        {text}
    </div>
);

const pipelineOptions = [
    { value: leadTypesEnums.rollOff, label: 'Roll Off Pipeline' },
    { value: leadTypesEnums.commercial, label: 'Commercial Pipeline' },
    { value: leadTypesEnums.residential, label: 'Residential Pipeline' },
    { value: leadTypesEnums.portableToilet, label: 'Portable Toilet Pipeline' },
];

const rollOffColumns: DataGridProps<Customer.AllianceLeadTransport>['columns'] = [
    {
        key: 'metadata',
        name: 'Created',
        align: 'center',
        formatter: ({ row }) => format(new Date(row.metadata.createdAt), 'MM/dd/yy hh:mm aaa'),
        width: 150,
    },
    {
        key: 'followUpDate',
        name: 'Follow Up',
        formatter: ({ row }) => {
            const value = row.followUpDate;
            return value && isAfter(new Date(), subDays(new Date(value), 1)) ? (
                <OrangePill text={format(new Date(value), 'MM/dd/yy')} />
            ) : value ? (
                format(new Date(value), 'MM/dd/yy')
            ) : (
                ''
            );
        },
        width: 90,
    },
    {
        key: 'contactEvents',
        name: 'Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) => (row.contactEvents.length > 0 ? 'Yes' : <RedPill text="No" />),
        width: 90,
    },
    {
        key: 'quotes',
        name: 'Quoted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) => (row.quotes.length > 0 ? 'Yes' : <RedPill text="No" />),
        width: 70,
    },
    {
        key: 'lastContacted',
        name: 'Last Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) =>
            !row.followUpDate &&
            row.contactEvents.length > 0 &&
            isAfter(new Date(), addDays(new Date(row.contactEvents[row.contactEvents.length - 1].date as string), 2)) ? (
                <OrangePill text={format(new Date(row.contactEvents[row.contactEvents.length - 1].date as string), 'MM/dd/yy hh:mm aaa')} />
            ) : row.contactEvents.length > 0 ? (
                format(new Date(row.contactEvents[row.contactEvents.length - 1].date as string), 'MM/dd/yy hh:mm aaa')
            ) : (
                ''
            ),
        width: 140,
    },
    {
        key: 'requestedDeliveryDate',
        name: 'Delivery Date',
        width: 120,
        formatter: ({ row }) => (row.requestedDeliveryDate ? format(new Date(row.requestedDeliveryDate), 'MM/dd/yy') : ''),
    },
    {
        key: 'name',
        name: 'Name',
        formatter: ({ row }) =>
            row.allianceCustomer ? (
                <GreenPill text={`${row.firstName} ${row.lastName}`} />
            ) : (
                <span>
                    {row.firstName} {row.lastName}
                </span>
            ),
        width: 200,
    },
    {
        key: 'serviceLocation',
        name: 'Location',
        formatter: ({ row }) => (
            <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(row.serviceLocation?.address || undefined)}</div>
        ),
    },
];

const commercialColumns: DataGridProps<Customer.AllianceLeadTransport>['columns'] = [
    {
        key: 'metadata',
        name: 'Created',
        align: 'center',
        formatter: ({ row }) => format(new Date(row.metadata.createdAt), 'MM/dd/yy hh:mm aaa'),
        width: 150,
    },
    {
        key: 'requestedStartDate',
        name: 'Requested Start',
        formatter: ({ row }) => (row.requestedStartDate ? format(new Date(row.requestedStartDate), 'MM/dd/yy') : null),
        width: 130,
    },
    {
        key: 'followUpDate',
        name: 'Follow Up',
        formatter: ({ row }) =>
            row.followUpDate && isAfter(new Date(), subDays(new Date(row.followUpDate), 1)) ? (
                <OrangePill text={format(new Date(row.followUpDate), 'MM/dd/yy')} />
            ) : row.followUpDate ? (
                format(new Date(row.followUpDate), 'MM/dd/yy')
            ) : (
                ''
            ),
        width: 90,
    },
    {
        key: 'contactEvents',
        name: 'Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) => (row.contactEvents.length > 0 ? 'Yes' : <RedPill text="No" />),
        width: 90,
    },
    {
        key: 'quotes',
        name: 'Quoted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) => (row.quotes.length > 0 && row.quotes.some((item) => item.price) ? 'Yes' : <RedPill text="No" />),
        width: 70,
    },
    {
        key: 'lastContacted',
        name: 'Last Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) =>
            row.contactEvents.length > 0
                ? format(new Date(row.contactEvents[row.contactEvents.length - 1].date as string), 'MM/dd/yy')
                : '',
        width: 140,
    },
    {
        key: 'name',
        name: 'Name',
        formatter: ({ row }) => `${row.firstName} ${row.lastName}`,
        width: 200,
    },
    {
        key: 'serviceLocation',
        name: 'Location',
        formatter: ({ row }) => (
            <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(row.serviceLocation?.address)}</div>
        ),
    },
    {
        key: 'size',
        name: 'Size',
        formatter: ({ row }) => (row.quotes.length === 0 ? '' : row.quotes.length > 1 ? 'Multi' : row.quotes[0].size.size),
    },
];

const portableToiletColumns: DataGridProps<Customer.AllianceLeadTransport>['columns'] = [
    {
        key: 'metadata',
        name: 'Created',
        align: 'center',
        formatter: ({ row }) => format(new Date(row.metadata.createdAt), 'MM/dd/yy hh:mm aaa'),
        width: 150,
    },
    {
        key: 'requestedStartDate',
        name: 'Requested Start',
        formatter: ({ row }) => (row.requestedStartDate ? format(new Date(row.requestedStartDate), 'MM/dd/yy') : null),
        width: 130,
    },
    {
        key: 'followUpDate',
        name: 'Follow Up',
        formatter: ({ row }) =>
            row.followUpDate && isAfter(new Date(), subDays(new Date(row.followUpDate), 1)) ? (
                <OrangePill text={format(new Date(row.followUpDate), 'MM/dd/yy')} />
            ) : row.followUpDate ? (
                format(new Date(row.followUpDate), 'MM/dd/yy')
            ) : (
                ''
            ),
        width: 90,
    },
    {
        key: 'contactEvents',
        name: 'Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) => (row.contactEvents.length > 0 ? 'Yes' : <RedPill text="No" />),
        width: 90,
    },
    {
        key: 'lastContacted',
        name: 'Last Contacted',
        align: 'center',
        headerAlign: 'center',
        formatter: ({ row }) =>
            row.contactEvents.length > 0
                ? format(new Date(row.contactEvents[row.contactEvents.length - 1].date as string), 'MM/dd/yy')
                : '',
        width: 140,
    },
    {
        key: 'name',
        name: 'Name',
        formatter: ({ row }) => `${row.firstName} ${row.lastName}`,
        width: 200,
    },
    {
        key: 'serviceLocation',
        name: 'Location',
        formatter: ({ row }) => (
            <div style={{ whiteSpace: 'pre', width: '100%' }}>{formatServiceAddress(row.serviceLocation?.address)}</div>
        ),
    },
];

const LeadsPage = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { showFlash } = useContext(UIContext);
    const [filterData, setFilterData] = useState<Customer.AllianceLeadTransport[]>([]);
    const [page, setPage] = useState(0);
    const [filterDataCount, setFilterDataCount] = useState(0);
    const [pageSize, setPageSize] = useState<number>(50);

    const [searchLoading, setSearchLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLeadType, setSelectedLeadType] = useState(leadTypesEnums.rollOff);
    const [columns, setColumns] = useState(rollOffColumns);
    const [showAdminPanel, setShowAdminPanel] = useState(false);

    ///////////////////////////////////////////////
    // NEW SEARCH
    ///////////////////////////////////////////////
    const defaultFilter = 'sourgum-lead';
    const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [searchData, setSearchData] = useState<Customer.AllianceLeadTransport[]>([]);
    const [totalHits, setTotalHits] = useState<number>(0);

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    // DEFINE ON RESULTS LOGIC
    const handleSearchResults = async (response: SourSearchResponseOld) => {
        setSearchLoading(true);
        if (page !== response.page) setPage(response.page);
        response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        if (response.type === 'sourgum-lead') {
            const res = await processHits(response.results.hits.hits);
            setTotalHits(response.totalHits);
            setSearchData(res);
        } else {
            setSearchData([]);
        }

        setSearchLoading(false);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };
    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    // THIS NEEDS TO BE SPEED UP
    const processHits = async (hits: SearchHit<unknown>[]) => {
        const results: Customer.AllianceLeadTransport[] = [];
        hits.map((hit) => {
            results.push(hit._source as Customer.AllianceLeadTransport);
        });
        return results;
    };

    ///////////////////////////////////////////////
    // END NEW SEARCH
    ///////////////////////////////////////////////

    useEffect(() => {
        setIsLoading(true);
        setFilterData([]);
        setFilterDataCount(0);
        const subscription = client.customer().adminPortal.leads.subscription.query({
            offset: page * pageSize,
            limit: pageSize,
            type: selectedLeadType as Customer.LeadType,
            status: 'open',
        });

        subscription.receive.subscribe({
            next: (data) => {
                setFilterData(data.results);
                setFilterDataCount(data.total);
                setIsLoading(false);
            },
            error: (error) => {
                showFlash((error as SocketError).additionalInfo || 'Error loading leads', 'warning');
                setIsLoading(false);
            },
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [page, pageSize, selectedLeadType]);

    const handleLeadTypeChange = (value: string) => {
        if (value === leadTypesEnums.rollOff) {
            setColumns(rollOffColumns);
        } else if (value === leadTypesEnums.commercial) {
            setColumns(commercialColumns);
        } else if (value === leadTypesEnums.residential) {
            setColumns(commercialColumns);
        } else if (value === leadTypesEnums.portableToilet) {
            setColumns(portableToiletColumns);
        }
        setSelectedLeadType(value);
    };

    return (
        <div className="shadow-dark full-page-height-parent m-2 flex flex-1 rounded-md bg-white">
            <div className="container mx-auto flex flex-1 flex-col px-5 py-2">
                <div className="flex items-end justify-between">
                    <div>
                        <h3 className="text-2xl">Leads</h3>
                    </div>
                    <div className="flex items-center">
                        <button className="btn-icon mr-7 text-gray-600" onClick={() => setShowAdminPanel(true)}>
                            <ShieldCheckIcon className="h-6 w-6" />
                        </button>
                        <ButtonSelect
                            label="Select A Pipeline"
                            menuItems={pipelineOptions}
                            value={selectedLeadType}
                            onSelect={handleLeadTypeChange}
                            wrapperClass="mr-5"
                        />
                    </div>
                </div>
                <hr className="mt-1" />
                <div className="flex flex-row pb-2 pt-3">
                    <div className="item-center flex w-full flex-row justify-between">
                        <SourSearchWrapperOld
                            options={{
                                application: 'aap',
                                apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                                environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                            }}
                            onNavigate={onNavigate}
                            onResults={handleSearchResults}
                            onSearch={onActiveSearch}
                            onFilter={onActiveFilter}
                            createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                            size={pageSize}
                            page={page}
                            defaultFilters={{
                                query: {
                                    type: defaultFilter,
                                },
                            }}
                        >
                            <div className="flex w-full flex-row justify-between space-x-4">
                                <SourSearchOld
                                    options={{
                                        searchPopoverFixed: false,
                                        showTips: !isDefaultFilter,
                                        showMessages: !isDefaultFilter,
                                        showResults: !isDefaultFilter,
                                        placeholder: 'Search Leads',
                                    }}
                                />
                                <SourFiltersOld />
                            </div>
                        </SourSearchWrapperOld>
                    </div>
                </div>
                <div className="flex h-0 w-full flex-1 grow basis-0">
                    <DataGrid
                        rows={searchActive || filterActive ? searchData : filterData}
                        rowHeight={30}
                        columns={columns}
                        loading={searchActive || filterActive ? searchLoading : isLoading}
                        pagination={{
                            onRowsPerPageChange: (newPageSize) => {
                                setPageSize(newPageSize);
                            },
                            onPageChange: (newPage) => {
                                setPage(newPage);
                            },
                            page: page,
                            rowsPerPage: pageSize,
                            rowsPerPageOptions: [25, 50, 100],
                            itemCount: searchActive || filterActive ? totalHits : filterDataCount,
                        }}
                        onRowClick={(row) => {
                            history.push(routes.leads.details(row.id), { modal: true });
                        }}
                    />
                </div>
            </div>
            {showAdminPanel ? (
                <AdminPanel open={showAdminPanel} onClose={() => setShowAdminPanel(false)} leadType={selectedLeadType} />
            ) : null}
        </div>
    );
};

export default LeadsPage;
