import {
    AddressTransport,
    Customer,
    Hauler,
    Material,
    MaterialLabels,
    Notification,
    Order,
    Pricing,
    Profile,
    ServiceLocationTransport,
} from '@alliance-disposal/transport-types';
import { formatServiceAddress, formatServiceDate, getDispatchEmailsString, getPrimaryCustomerContact, moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';
import { aggregateMaterialKeys, noReplyEmail } from './shared-types';

///////////// Start Local Helper Functions //////////////
const createWeightLimitString = (values: { weightLimit?: number | ''; material: Material | ''; expectedSize: number | '' }) => {
    if (values.weightLimit || values.weightLimit === 0) {
        return `${values.weightLimit} tons`;
    } else if (values.material && aggregateMaterialKeys.indexOf(values.material) > -1 && +values.expectedSize >= 20) {
        return 'You may only fill the dumpster 3/4 of the way. If filled to the top we may refuse pickup or charge an overfilled fee.';
    } else {
        return 'N/A';
    }
};

const createAsbestosOnlyString = (material: Material | '') => {
    if (material === 'asbestos' || material === 'asbestosNonFriable') {
        return 'Asbestos must be sealed in a dumpster liner or in approved bags. ONLY fill the container with asbestos. No other material may be placed into the dumpster. Doing so could result in a denial of pickup or extra fees.';
    }
    return '';
};

const createProhibitedItemList = (material: Material, prohibitedItemString: string | undefined | null) => {
    switch (material) {
        case 'transportation':
            return 'This is a transportation only load.';
        case 'scrap':
            return 'Only scrap metal may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'creosote':
            return 'Only creosote may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'asbestos':
        case 'asbestosNonFriable':
            return 'Asbestos must be sealed in a dumpster liner or in approved bags. Only asbestos may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'cardboard':
            return 'Only cardboard may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'singleStream':
            return 'Only recyclables may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'dirt':
            return 'Only dirt may be placed into the dumpster. Trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'asphalt':
            return 'Only asphalt may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'comix':
            return 'Only concrete and brick may be placed into the dumpster. Dirt, trash, concrete slurry and other debris may NOT be placed in the dumpster. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'concrete':
        case 'concreteRebar':
            return 'Only clean concrete may be placed into the dumpster. Dirt, trash, concrete slurry and other debris may NOT be placed in the dumpster. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        case 'bNB':
        case 'bNL':
        case 'bNS':
            return 'Only organic yard waste may be placed into the dumpster. Dirt, trash and other debris may NOT. Placing other items into the container may result in additional fees or refusal of pickup unless otherwise noted in writing.';
        default:
            return (
                'Placing prohibited items into the dumpster will result in additional fees or refusal of pickup unless otherwise noted in writing. Prohibited items include: ' +
                (prohibitedItemString ||
                    'Aerosol cans, Dirt, Fluorescent tubes, Flammable liquids, Animals, Paint (except completely dried or empty), Hazardous waste, Lead paint chips, Appliances containing Freon, Herbicides & pesticides, Propane tanks, Asbestos, Industrial waste, Radioactive material, Lubricating/hydraulic oil, Railroad ties, Batteries, Medical waste, Solvents, Chemicals, Televisions, Monitors, Tires, gasoline and other hazardous objects.')
            );
    }
};
///////////// End Local Helper Functions //////////////

export const getCustomerCCAddresses = (toEmail: string, customer: Customer.AllianceCustomerTransport, location?: AddressTransport) => {
    const serviceCCAddresses: string[] = [];
    const billingCCAddresses: string[] = [];

    if (location) {
        if (
            customer.contacts &&
            customer.contacts.length > 0 &&
            customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
        ) {
            customer.contacts.forEach((item) => {
                const locationPreferences = item?.locationPreferences || [];
                let preference = locationPreferences.some((area) => area === formatServiceAddress(location));

                if (locationPreferences.length === 0) {
                    preference = true;
                }

                if (item.email && item.sendDispatchEmails && item.email !== toEmail && preference) serviceCCAddresses.push(item.email);
                if (item.email && item.sendBillingEmails && item.email !== toEmail && preference) billingCCAddresses.push(item.email);
            });
        }
    } else {
        if (
            customer.contacts &&
            customer.contacts.length > 0 &&
            customer.contacts.some((item) => item.sendDispatchEmails || item.sendBillingEmails)
        ) {
            customer.contacts.forEach((item) => {
                if (item.email && item.sendDispatchEmails && item.email !== toEmail) serviceCCAddresses.push(item.email);
                if (item.email && item.sendBillingEmails && item.email !== toEmail) billingCCAddresses.push(item.email);
            });
        }
    }
    return { serviceCCAddresses, billingCCAddresses };
};

/**
 * Generates the correct to and cc email addresses for the customer based on the email type and location. Also returns the "main" contact that the email is being sent to
 * @param emailType billing will only send it to customers meant to receive billing emails. dispatch will only send it to customers meant to receive dispatch emails
 */
export const getCustomerToAndCCEmails = (
    emailType: 'billing' | 'dispatch',
    customer: Customer.AllianceCustomerTransport,
    location?: AddressTransport,
) => {
    const primaryContact = getPrimaryCustomerContact(customer);
    const fieldName = emailType === 'dispatch' ? 'sendDispatchEmails' : 'sendBillingEmails';
    if (
        customer.contacts.every((contact) => !contact[fieldName]) ||
        (location &&
            customer.contacts
                .filter(
                    (contact) =>
                        !contact.locationPreferences ||
                        contact.locationPreferences?.length === 0 ||
                        contact.locationPreferences?.includes(formatServiceAddress(location)),
                )
                .every((contact) => !contact[fieldName]))
    ) {
        throw new Error(`No ${emailType} contacts found${location ? ' for this location' : ''}`);
    }
    const toContact = primaryContact[fieldName]
        ? primaryContact
        : customer.contacts.filter((contact) => contact[fieldName]).sort((a, b) => ((a.email || '') > (b.email || '') ? 1 : -1))[0];
    if (!toContact?.email) throw new Error(`No ${emailType} emails found`);
    const ccEmails = getCustomerCCAddresses(toContact.email, customer, location);
    return { to: toContact.email, toContact, cc: emailType === 'dispatch' ? ccEmails.serviceCCAddresses : ccEmails.billingCCAddresses };
};

/**
 * This is only used to show a preview of the email in the admin portal, not to actually send the email
 */
export const createOrderConfirmationEmailPreview = (
    values: {
        serviceLocation: ServiceLocationTransport;
        expectedDeliveryDate: Date | '';
        sharedDeliveryNotes: string;
        expectedSize: number | '';
        adjustedRentalPeriod: number | '';
        rentExtensionFee: number | '';
        overage: number | '';
        expectedPickupDate?: Date | '';
        emailMessage?: string;
        material: Material | '';
        status?: Order.OrderStatus;
        weightLimit?: number | '';
    },
    orderTotal: number,
    user: Profile.ProfileTransport,
    prohibitedItemString: string | undefined | null,
): string => {
    const tosString =
        'By accepting the dumpster delivery you are accepting the dumpster rental terms of service. Which can be found at https://www.sourgum.com/terms-of-service-roll-off/ . ';
    const dashboardString =
        values.status === 'NEEDS_REVIEW'
            ? `If you need to reorder, or make any changes to your current orders, log in to the Sourgum Dashboard at https://dashboard.sourgum.com`
            : `Make it even easier to reorder, or change your current orders: sign up to the Sourgum Dashboard for full access at https://dashboard.sourgum.com/signup`;
    const emailItems = [
        {
            label: 'Delivery address',
            value: formatServiceAddress(values.serviceLocation.address),
        },
        {
            label: 'Delivery date',
            value: values.expectedDeliveryDate
                ? format(formatServiceDate(values.expectedDeliveryDate, 'date') as Date, 'EEEE MM/dd/yy')
                : '',
        },
        { label: 'Delivery instructions', value: values.sharedDeliveryNotes },
        { label: 'Size', value: values.expectedSize + ' yard dumpster' },
        { label: 'Material', value: values.material ? MaterialLabels[values.material] : '' },
        { label: 'Weight limit', value: createWeightLimitString(values) },
        { label: 'Price', value: moneyFormatter(orderTotal) },
        {
            label: 'Overage fee',
            value: values.overage ? `If applicable ${moneyFormatter(values.overage)} per additional ton, prorated` : 'N/A',
        },
        {
            label: 'Rental period',
            value: `${
                values.adjustedRentalPeriod
            } days, you can call or email us any time before for a pick up or extend your rental for ${moneyFormatter(
                values.rentExtensionFee || undefined,
            )} per day. We do not pickup your dumpster automatically. Please note there is a 30 day maximum on all rental extensions.`,
        },
    ];
    if (values.expectedPickupDate) {
        emailItems.push({
            label: 'Pickup date',
            value: format(formatServiceDate(values.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy'),
        });
    }
    if (createAsbestosOnlyString(values.material)) {
        emailItems.push({
            label: 'Asbestos Notice',
            value: createAsbestosOnlyString(values.material),
        });
    }

    const sharedText =
        'To ensure a successful delivery of the dumpster please make sure that the driveway and surrounding area are clear of cars and any obstructions between the hours of 6:30 am and 6 pm on the day your dumpster is scheduled to be delivered. Please move all cars the night before. Any changes made to your order after 12pm the business day before your service date may not be possible. Please remember that you cannot fill the container above the top rim. You can cancel your order before your delivery date, there is a $25 cancellation fee. If we are unable to deliver the dumpster successfully there will be a trip charge.';

    const emailStaticHtml =
        `<p>${dashboardString}</p><p><u>Important:</u> ${sharedText}</p>` +
        emailItems.map((item) => `<p><b>${item.label}:</b> ${item.value}</p>`).join('') +
        `<p>${createProhibitedItemList(
            values.material as Material,
            prohibitedItemString,
        )}</p><p>If you have any questions please let me know and I'll be happy to help you.</p><p>Thank you,</p><p>${user?.firstName}</p>`;

    const customizableHTMLMessage = `
      ${`<p>${tosString}</p>`}
      ${emailStaticHtml}
    `;

    return customizableHTMLMessage;
};

export const createOrderConfirmationEmailSendGrid = (
    values: {
        contact?: Customer.AllianceCustomerContactTransport;
        serviceLocation: ServiceLocationTransport;
        expectedDeliveryDate: string;
        sharedDeliveryNotes: string;
        expectedSize: number;
        adjustedRentalPeriod: number;
        rentExtensionFee: number;
        overage?: number;
        expectedPickupDate?: string;
        emailMessage?: string;
        material: Material;
        weightLimit: number | '';
    },
    orderTotal: number,
    user: Profile.ProfileTransport,
    prohibitedItemString: string | undefined | null,
    orderNumber: string,
): Notification.SendGrid.OrderConfirmationRo => {
    const data: Notification.SendGrid.OrderConfirmationRo = {
        first_name: values.contact?.firstName as string,
        service_location: formatServiceAddress(values.serviceLocation.address),
        delivery_date: format(formatServiceDate(values.expectedDeliveryDate, 'date') as Date, 'EEEE MM/dd/yy'),
        delivery_instructions: values.sharedDeliveryNotes,
        material: MaterialLabels[values.material],
        dumpster_size: values.expectedSize.toString(),
        weight_limit: createWeightLimitString(values),
        rental_period: values.adjustedRentalPeriod.toString(),
        rental_extension_fee: moneyFormatter(values.rentExtensionFee, { hideDollarSign: true, undefinedBehavior: '' }),
        overage_fee: values.overage ? moneyFormatter(values.overage, { hideDollarSign: true, undefinedBehavior: '' }) : '',
        order_total: moneyFormatter(orderTotal, { hideDollarSign: true, undefinedBehavior: '' }),
        pickup_date: values.expectedPickupDate ? format(formatServiceDate(values.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy') : '',
        csa_name: user.firstName as string,
        prohibited_items_string: createProhibitedItemList(values.material, prohibitedItemString),
        custom_message: values.emailMessage || '',
        payment_link: '',
        order_number: orderNumber,
    };
    return data;
};

export const createOrderReadyPickupEmailSendGrid = (values: {
    contact?: Customer.AllianceCustomerContactTransport;
    user: Profile.ProfileTransport;
    material: Material;
    prohibitedItemString: string | undefined | null;
    orderNumber: string | number;
    serviceLocation: ServiceLocationTransport;
    expectedPickupDate: string | Date;
    sharedPickupNotes?: string;
}) => {
    const data: Notification.SendGrid.RoReadyPickup = {
        first_name: values.contact?.firstName || '',
        csa_name: values.user.firstName || '',
        prohibited_items_list: createProhibitedItemList(values.material, values.prohibitedItemString),
        order_number: values.orderNumber?.toString() || '',
        service_location: formatServiceAddress(values.serviceLocation.address),
        pickup_date: format(formatServiceDate(values.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy'),
        pickup_notes: values.sharedPickupNotes || '',
    };

    return data;
};

export const createOrderSwitchEmailSendGrid = (values: {
    contact: Customer.AllianceCustomerContactTransport;
    user: Profile.ProfileTransport;
    prohibitedItemString: string | undefined | null;
    material: Material;
    serviceLocation: ServiceLocationTransport;
    expectedSize: number;
    weightLimit: number | '';
    orderTotal: number;
    priceType: Pricing.TypesOfPricing;
    rentExtensionFee: number;
    overage: number | '';
    adjustedRentalPeriod: number;
    expectedPickupDate: string | Date;
    sharedDeliveryNotes: string;
    payment_link: string;
}) => {
    const data: Notification.SendGrid.RoReadyPickupSwap = {
        first_name: values.contact?.firstName || '',
        csa_name: values.user.firstName || '',
        prohibited_items_list: createProhibitedItemList(values.material, values.prohibitedItemString),
        service_location: formatServiceAddress(values.serviceLocation.address),
        pickup_date: format(formatServiceDate(values.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy'),
        delivery_notes: values.sharedDeliveryNotes,
        dumpster_size: values.expectedSize?.toString(),
        material: MaterialLabels[values.material],
        weight_limit: createWeightLimitString(values),
        order_total: moneyFormatter(values.orderTotal, { hideDollarSign: true }),
        rental_period: values.adjustedRentalPeriod.toString(),
        overage_fee: values.overage ? moneyFormatter(values.overage, { hideDollarSign: true, undefinedBehavior: '' }) : '',
        price_type: values.priceType,
        rental_extension_fee: moneyFormatter(values.rentExtensionFee || undefined, {
            hideDollarSign: true,
            undefinedBehavior: '',
        }),
        payment_link: values.payment_link,
    };
    return data;
};

export const createOrderCancellationEmailSendGrid = (values: {
    contact: Customer.AllianceCustomerContactTransport;
    user: Profile.ProfileTransport;
    orderNumber: string | number;
    serviceLocation: ServiceLocationTransport;
}) => {
    const data: Notification.SendGrid.OrderCancellationRo = {
        first_name: values.contact.firstName || '',
        csa_name: values.user.firstName || '',
        order_number: values.orderNumber?.toString() || '',
        service_location: formatServiceAddress(values.serviceLocation.address),
    };

    return data;
};

export const createOrderPlaceOnHoldEmail = (customer: Customer.AllianceCustomerTransport, order: Order.AllianceOrderTransport) => {
    const customerEmailData = {
        htmlMessage: `
      <p>Hi ${getPrimaryCustomerContact(customer)?.firstName},</p>
      <p>Your dumpster rental order to ${formatServiceAddress(
          order?.serviceLocation.address,
      )} has been successfully placed on hold. If you have any questions please let us know and we'll be happy to help you.</p>
      <p>Thank you!</p>
      <p>The Sourgum Waste Team</p>
    `,
        textMessage: `
      Hi ${getPrimaryCustomerContact(customer)?.firstName},\n\n
      Your dumpster rental order to ${formatServiceAddress(
          order?.serviceLocation.address,
      )} has been successfully placed on hold. If you have any questions please let us know and we'll be happy to help you
      \n\nThank you!
      \n\nThe Sourgum Waste Team
    `,
        toAddress: getPrimaryCustomerContact(customer)?.email,
        subject: 'Sourgum Waste Dumpster Placed On Hold',
        bcc: noReplyEmail,
        ccAddresses:
            customer &&
            getCustomerCCAddresses(getPrimaryCustomerContact(customer)?.email || '', customer, order?.serviceLocation.address)
                .serviceCCAddresses,
    };
    return customerEmailData;
};

export const createHaulerOrderConfirmationEmail = (
    order: Order.AllianceOrderTransport,
    hauler: Hauler.HaulerWithAapTransport,
    haulerPricing?: Pricing.PricingDataTransport,
) => {
    const sizePricing = haulerPricing?.sizes ? haulerPricing.sizes.find((item) => +item.size === Number(order.expectedSize.size)) : null;
    const blendedData = {
        type: haulerPricing?.type || order.priceType,
        haul: sizePricing?.haul || order.haulerPricingSnapshot?.haul,
        dump: sizePricing?.dump || order.haulerPricingSnapshot?.dump,
        tonLimit: sizePricing?.tonLimit || order.haulerPricingSnapshot?.tonLimit?.value,
    };
    const emailData = [
        { label: 'PO Number', value: `${order.orderNumber}` },
        { label: 'Delivery Address', value: formatServiceAddress(order.serviceLocation.address) },
        {
            label: 'Delivery Date',
            value: format(formatServiceDate(order.expectedDeliveryDate, 'date') as Date, 'EEEE MM/dd/yy'),
        },
        {
            label: 'Pick Up Date',
            value: order.expectedPickupDate ? format(formatServiceDate(order.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy') : null,
        },
        {
            label: 'Delivery Instructions',
            value: `${order.sharedDeliveryNotes}. If there are any issues call (732) 366-9355 while still on site.`,
        },
        { label: 'Size', value: `${order.expectedSize.size}  yard dumpster` },
        {
            label: 'Material',
            value: MaterialLabels[order.material as Material],
        },
        { label: 'County', value: order.serviceLocation.county },
        {
            label: 'Haul Rate',
            value: moneyFormatter(blendedData?.haul ? +blendedData?.haul : 0),
        },
        {
            label: 'Dump Rate',
            value: moneyFormatter(blendedData?.dump ? +blendedData?.dump : 0),
        },
    ];
    if (order.sharedDeliveryNotesPrivate) {
        emailData.push({
            label: 'Additional Notes',
            value: order.sharedDeliveryNotesPrivate,
        });
    }
    // IF pricing type is ton, and the ton limit is GREATER THAN 0 then add Ton Limit and Subtotal
    if (blendedData?.tonLimit && Number(blendedData.tonLimit) > 0 && blendedData.type !== 'flat') {
        if (blendedData.dump !== null && blendedData.haul !== undefined && blendedData.dump !== undefined) {
            emailData.push({ label: 'Ton Limit', value: `${blendedData?.tonLimit} tons` });
            const subTotal = blendedData?.haul + blendedData?.dump * blendedData.tonLimit;
            emailData.push({ label: 'Subtotal', value: moneyFormatter(subTotal) });
        }
        // ELSE if pricing type is NOT ton show Ton Limit as N/A
    } else if (blendedData.type !== 'ton') {
        emailData.push({ label: 'Ton Limit', value: 'N/A' });
    }
    const confirmationUrl = 'https://app.wayste.com/update-alliance/';
    const htmlMessage = `
        <p>Sourgum Waste dumpster rental confirmation details:</p>
        ${emailData
            .map((item) => {
                if (
                    (item.label === 'Pick Up Date' && !item.value) ||
                    (item.label === 'Dump Rate' && (blendedData?.dump ? +blendedData?.dump : 0) === 0)
                )
                    return '';

                return `<p><b>${item.label}:</b> ${item.value}</p>`;
            })
            .join('')}
        <p style="margin: 20px 0">
          <a href="${confirmationUrl}?category=delivery_confirmed&id=${
              order.id
          }" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm Delivery</a>
        </p>
        <p>Please click above on the Confirm Delivery button if you are accepting this order at the above details.</p>
        <p>Please submit an individual invoice and dump slip for each order for prompt payment.</p>
        <p>If the button does not work, please go to ${confirmationUrl}?category=delivery_confirmed&id=${
            order.id
        } or reply Confirmed to this email.</p>
      `;

    const textMessage = `
        Sourgum Waste dumpster rental confirmation details:
        ${emailData
            .map((item) => {
                if (
                    (item.label === 'Pick Up Date' && !item.value) ||
                    (item.label === 'Dump Rate' && (blendedData?.dump ? +blendedData?.dump : 0) === 0)
                )
                    return '';
                return `\n\n${item.label}: ${item.value}`;
            })
            .join('')}
        \n\nPlease go to ${confirmationUrl}?category=delivery_confirmed&id=${order.id} or reply Confirmed to accept this order. Please submit an individual invoice and dump slip for each order for prompt payment.
      `;

    return {
        htmlMessage,
        textMessage,
        toAddress: getDispatchEmailsString(hauler.contacts),
        subject: `Sourgum Waste Dumpster Confirmation - ${order.orderNumber}`,
        bcc: noReplyEmail,
    };
};

export const createHaulerOrderCancellationEmail = (
    hauler: Hauler.HaulerWithAapTransport,
    order: Order.AllianceOrderTransport,
    switchCancellation?: 'final_removal' | 'delay_pickup' | null,
) => {
    let openingString = `Please Cancel the dumpster delivery for Sourgum Waste. Order #${order.orderNumber}.`;
    if (switchCancellation === 'final_removal') {
        openingString = `Please Cancel the dump & return for Sourgum Waste and do a final removal instead. Order #${order.orderNumber}.`;
    }
    if (switchCancellation === 'delay_pickup') {
        openingString = `Please Cancel the dump & return for Sourgum Waste and wait for us to let you know a new pickup date. Order #${order.orderNumber}.`;
    }

    return {
        htmlMessage: `
      <p>${openingString}</p>
      <p>Cancellation details:</p>
      <p><b>Address:</b> ${formatServiceAddress(order.serviceLocation.address)}</p>
      <p><b>Size:</b> ${order.expectedSize.size}</p>
      <p><b>Date:</b> ${format(formatServiceDate(order.expectedDeliveryDate, 'date') as Date, 'EEEE MM/dd/yy')}</p>
      <p>Thank you!</p>
    `,
        textMessage: `
      ${openingString}\n\n
      Cancellation details:\n\n
      Address: ${formatServiceAddress(order.serviceLocation.address)}\n\n
      Size: ${order.expectedSize.size}\n\n
      Date: ${format(formatServiceDate(order.expectedDeliveryDate, 'date') as Date, 'EEEE MM/dd/yy')}\n\n
      Thank you!\n\n
    `,
        toAddress: getDispatchEmailsString(hauler.contacts),
        subject: `Sourgum Waste Dumpster Cancellation - ${order.orderNumber}`,
        bcc: noReplyEmail,
    };
};

export const createHaulerPickupEmail = (
    order: Order.AllianceOrderTransport,
    hauler: Hauler.HaulerWithAapTransport,
    isSwitch: boolean,
    switchValues?: {
        newOrderID: string;
        newOrderNumber: number;
        newNoteDel?: string;
        material: Material;
        newHaulerOnlyNote?: string;
        newSize: number;
    },
) => {
    const haulerEmailItems = [
        { label: 'Pickup Order Number', value: order.orderNumber },
        { label: 'Service Address', value: formatServiceAddress(order.serviceLocation.address) },
        {
            label: `${isSwitch ? 'Dump and Return' : 'Pickup'} Date`,
            value: order.expectedPickupDate ? format(formatServiceDate(order.expectedPickupDate, 'date') as Date, 'EEEE MM/dd/yy') : '',
        },
        {
            label: `${isSwitch ? 'Pickup ' : ''}Size`,
            value: `${order.expectedSize.size} yard dumpster`,
        },
        {
            label: `${isSwitch ? 'Pickup ' : ''}Material`,
            value: MaterialLabels[order.material as Material],
        },
        { label: 'County', value: order.serviceLocation.county },
        {
            label: 'Pickup Instructions',
            value: `${order.sharedPickupNotes}. If there are any issues call (732) 366-9355 while still on site.`,
        },
        { label: 'New Delivery Order Number', value: switchValues?.newOrderNumber },
        {
            label: 'Delivery Size',
            value: isSwitch ? `${switchValues?.newSize} yard dumpster` : '',
        },
        {
            label: 'Delivery Instructions',
            value: isSwitch ? switchValues?.newNoteDel : '',
        },
        {
            label: 'Delivery Material',
            value: isSwitch && switchValues?.material ? MaterialLabels[switchValues.material] : '',
        },
    ];
    if (order.sharedPickupNotesPrivate || switchValues?.newHaulerOnlyNote) {
        haulerEmailItems.push({
            label: 'Additional Notes',
            value: order.sharedPickupNotesPrivate + ' ' + switchValues?.newHaulerOnlyNote,
        });
    }
    const confirmationUrl = 'https://app.wayste.com/update-alliance/';
    const confirmationId = switchValues?.newOrderID ? `id=${order.id}&switch=${switchValues.newOrderID}` : `id=${order.id}`;
    const haulerEmailHtml = `
    <p>Sourgum Waste dumpster rental ${isSwitch ? 'DUMP AND RETURN' : 'PICKUP FINAL REMOVAL'} confirmation details:</p>
    ${haulerEmailItems
        .map((item) => {
            if (
                (!isSwitch && item.label === 'Delivery Size') ||
                (!isSwitch && item.label === 'Delivery Instructions') ||
                (!isSwitch && item.label === 'Delivery Material') ||
                (!isSwitch && item.label === 'New Delivery Order Number')
            )
                return '';
            return `<p><b>${item.label}:</b> ${item.value}</p>`;
        })
        .join('')}
    <p style="margin: 20px 0">
      <a href="${confirmationUrl}?category=pickup_confirmed&${confirmationId}" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);">Click Here to Confirm ${
          isSwitch ? 'Switch' : 'Pickup'
      }</a>
    </p>
    <p>Please click above on the Confirm ${isSwitch ? 'Switch' : 'Pickup'} button if you are accepting this order.</p>
    <p>Please submit an individual invoice and dump slip for each order for prompt payment.</p>
    <p>If the button does not work, please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to this email.</p>
  `;

    const haulerEmailText = `Sourgum Waste dumpster rental ${isSwitch ? 'DUMP AND RETURN' : 'PICKUP FINAL REMOVAL'} confirmation details:
  ${haulerEmailItems
      .map((item) => {
          if (
              (!isSwitch && item.label === 'Delivery Size') ||
              (!isSwitch && item.label === 'Delivery Instructions') ||
              (!isSwitch && item.label === 'Delivery Material') ||
              (!isSwitch && item.label === 'New Delivery Order Number')
          )
              return '';
          return `\n\n${item.label}: ${item.value}`;
      })
      .join('')}
  Please go to ${confirmationUrl}?category=pickup_confirmed&${confirmationId} or reply Confirmed to confirm this ${
      isSwitch ? 'Switch' : 'Pickup'
  }. Please submit an individual invoice and dump slip for each order for prompt payment.`;

    return {
        htmlMessage: haulerEmailHtml,
        textMessage: haulerEmailText,
        toAddress: getDispatchEmailsString(hauler.contacts),
        subject: `Pickup ${isSwitch ? 'Dump and Return' : 'Final Removal'} - ${order.orderNumber}`,
        bcc: noReplyEmail,
    };
};
