import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Pricing } from '@alliance-disposal/transport-types';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { cloneDeep } from 'lodash';
import DetailsCardWrapper from '../DetailsCardWrapper';
import CustomMaterialPricingModal from './components/CustomMaterialPricingModal';
import CustomPricingTable from './components/CustomPricingTable';
import CustomPricingZoneModal from './components/CustomPricingZoneModal';
import DeleteCustomMaterialPricingModal, { DeleteMaterialProps } from './components/DeleteCustomMaterialPricingModal';
import DeleteCustomPricingModal from './components/DeleteCustomPricingModal';

const CustomPricing = ({ customer }: { customer: Customer.AllianceCustomerTransport }) => {
    const client = useWaysteClient();
    const [customerDiscounts, setCustomerDiscounts] = useState<Pricing.CustomerDiscountTransport[]>();
    const [pricingModalProps, setPricingModalProps] = useState<
        | {
              customer: Customer.AllianceCustomerTransport;
              selectedCustomerDiscount: Pricing.CustomerDiscountTransport;
              selectedDiscountData?: Pricing.DiscountDataTransport;
          }
        | undefined
    >(undefined);
    const [pricingZoneModalProps, setPricingZoneModalProps] = useState<
        | {
              open: boolean;
              existingCustomerDiscounts: Pricing.CustomerDiscountTransport[];
              selectedCustomerDiscount?: Pricing.CustomerDiscountTransport;
          }
        | undefined
    >(undefined);
    const [deletePricingModalProps, setDeletePricingModalProps] = useState<
        { customer: Customer.AllianceCustomerTransport; customDiscount: Pricing.CustomerDiscountTransport } | undefined
    >(undefined);
    const [deleteMaterialPricingModalProps, setDeleteMaterialPricingModalProps] = useState<DeleteMaterialProps | Record<string, never>>({});

    const getCustomerDiscounts = async () => {
        try {
            const customerDiscounts = await client
                .pricing()
                .adminPortal.customerDiscount.query({ customerID: customer.id, contractor: false });
            const deep = cloneDeep(customerDiscounts.results);
            deep.sort((a, b) => ((a.pricingZone?.zoneName || '') > (b.pricingZone?.zoneName || '') ? 1 : -1));
            setCustomerDiscounts(deep);
        } catch (error) {
            console.warn('handleSetExpandedSection ERROR', error);
            alert('An error has occurred while fetching customer discounts.');
        }
    };

    const handleDeletePricingZone = async (customerDiscountID: string) => {
        try {
            await client.pricing().adminPortal.customerDiscount.delete(customerDiscountID);
            // Optimistic UI to prevent using setTimeout because refetching sometimes still grabs an expired pricing zone
            const findIndex = customerDiscounts?.findIndex((x) => x.id === customerDiscountID);
            const deep = cloneDeep(customerDiscounts);
            if (findIndex === undefined || findIndex === -1) return;
            deep?.splice(findIndex, 1);
            setCustomerDiscounts(deep?.sort((a, b) => ((a.pricingZone?.zoneName || '') > (b.pricingZone?.zoneName || '') ? 1 : -1)));
        } catch (error) {
            console.warn('handleDeletePricingZone error', error);
            alert('An error has occurred. Please contact AAP dev.');
        }
    };

    const handleDeleteMaterial = async ({ customerDiscountID, material }: { customerDiscountID: string; material: string }) => {
        try {
            const deep = cloneDeep(customerDiscounts);
            const findZoneIndex = deep?.findIndex((x) => x.id === customerDiscountID);
            if (findZoneIndex === undefined || findZoneIndex === -1 || !deep) return;
            const customerDiscount = deep[findZoneIndex];
            const filteredData = customerDiscount.discountData.filter((x) => x.material !== material);
            customerDiscount.discountData = filteredData;
            customerDiscount.discountData = customerDiscount.discountData.map((x) => {
                return {
                    material: x.material,

                    sizes: x.sizes.map((y) => {
                        const sizeMapped: Pricing.DiscountDataTransport['sizes'][0] = {
                            haul: y.haul,
                            dump: y.dump,
                            over: y.over,
                            tonLimit: y.tonLimit,
                            size: y.size,
                        };
                        return sizeMapped;
                    }),
                };
            });
            await client.pricing().adminPortal.customerDiscount.update(customerDiscountID, {
                discountData: customerDiscount.discountData,
            });
            await getCustomerDiscounts();
        } catch (error) {
            console.warn('handleDeleteMaterial ERROR', error);
            alert('An error has occurred, please contact AAP dev.');
        }
    };

    useEffect(() => {
        getCustomerDiscounts();
    }, []);

    const handleCreateUpdatePricingZone = async () => {
        setPricingZoneModalProps(undefined);
        getCustomerDiscounts();
    };

    return (
        <DetailsCardWrapper
            heading="Custom Pricing"
            buttons={[
                {
                    label: (
                        <>
                            <PlusIcon className="mr-1 size-5" />
                            Add Pricing Zone
                        </>
                    ),
                    onClick: () => {
                        setPricingZoneModalProps({
                            existingCustomerDiscounts: customerDiscounts ?? [],
                            open: true,
                        });
                    },
                },
            ]}
        >
            <DeleteCustomPricingModal
                handleClose={() => setDeletePricingModalProps(undefined)}
                handleDeletePricingZone={handleDeletePricingZone}
                customDiscount={deletePricingModalProps?.customDiscount}
                customer={deletePricingModalProps?.customer}
            />
            <DeleteCustomMaterialPricingModal
                {...deleteMaterialPricingModalProps}
                handleClose={() => setDeleteMaterialPricingModalProps({})}
                handleDeleteMaterial={handleDeleteMaterial}
            />
            <CustomMaterialPricingModal
                handleClose={() => setPricingModalProps(undefined)}
                onSubmit={getCustomerDiscounts}
                {...pricingModalProps}
            />
            <CustomPricingZoneModal
                onClose={() => setPricingZoneModalProps(undefined)}
                onSubmit={handleCreateUpdatePricingZone}
                open={Boolean(pricingZoneModalProps?.open)}
                existingCustomDiscounts={pricingZoneModalProps?.existingCustomerDiscounts}
                selectedCustomDiscount={pricingZoneModalProps?.selectedCustomerDiscount}
                customerId={customer.id}
            />
            <div style={{ margin: '-16.7px -20px -20px' }}>
                {customerDiscounts?.map((customerDiscount) => (
                    <Disclosure key={customerDiscount.id}>
                        <Disclosure.Button className="bg-blue-gray-50 flex w-full items-center justify-between px-4 py-3 text-left">
                            <p className="font-bold">
                                {`${customerDiscount?.pricingZone?.zoneName} (${customerDiscount?.pricingZone?.state || 'N/A'})` ??
                                    'No Zone Name'}
                            </p>
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        setPricingModalProps({
                                            customer: customer,
                                            selectedCustomerDiscount: customerDiscount,
                                        });
                                    }}
                                    className={`btn-primary relative mr-2`}
                                >
                                    <PlusIcon className="mr-1 size-5" />
                                    Add Material
                                </button>
                                <button
                                    onClick={() => {
                                        setPricingZoneModalProps({
                                            existingCustomerDiscounts: customerDiscounts ?? [],
                                            open: true,
                                            selectedCustomerDiscount: customerDiscount,
                                        });
                                    }}
                                    className={`btn-secondary relative mr-2`}
                                >
                                    <PencilIcon className="mr-1 size-5" />
                                    Edit Pricing Zone
                                </button>
                                <button
                                    onClick={() => {
                                        customerDiscount?.pricingZoneID
                                            ? setDeletePricingModalProps({
                                                  customer: customer,
                                                  customDiscount: customerDiscount,
                                              })
                                            : null;
                                    }}
                                    className={`btn-delete relative mr-4`}
                                >
                                    <TrashIcon className="mr-1 size-5" />
                                    Delete Zone
                                </button>
                                <ChevronDownIcon className="size-6" />
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            <CustomPricingTable
                                customer={customer}
                                customerDiscount={customerDiscount}
                                onDeleteClick={(materialDiscountInfo) =>
                                    setDeleteMaterialPricingModalProps({
                                        customer: customer,
                                        material: materialDiscountInfo.material,
                                        customerDiscount: customerDiscount,
                                    })
                                }
                                onEditClick={(materialDiscountInfo) =>
                                    setPricingModalProps({
                                        customer: customer,
                                        selectedCustomerDiscount: customerDiscount,
                                        selectedDiscountData: materialDiscountInfo,
                                    })
                                }
                            />
                        </Disclosure.Panel>
                    </Disclosure>
                ))}
            </div>
        </DetailsCardWrapper>
    );
};

export default CustomPricing;
