import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, Order } from '@alliance-disposal/transport-types';
import { DatePicker, Loading } from '@wayste/sour-ui';
import { centsToDollars, round } from '@wayste/utils';
import {
    format, // isBefore, subDays
} from 'date-fns';
import { CSVLink } from 'react-csv';
import XLSX from 'xlsx';

const inputAccept = '.csv, .xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

interface AllianceOrderWithReceivables extends Order.AllianceOrderTransport {
    receivables: Invoice.ReceivableTransport[];
    bills: Invoice.PayableTransport[];
}

const MarketingReports = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<'' | Date>('');
    const [googleClickData, setGoogleClickData] = useState<any[]>([]);
    const [googleCallData, setGoogleCallData] = useState<any[]>([]);
    const [microsoftClickData, setMicrosoftClickData] = useState<any[]>([]);
    const [microsoftCallData, setMicrosoftCallData] = useState<any[]>([]);
    const client = useWaysteClient();

    const getOrdersWithInvoice = async (orderData: Order.AllianceOrderTransport[]) => {
        const orderIds = orderData.map((order) => order.id);

        if (orderIds.length === 0) return [];

        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const payables = await client.invoice().adminPortal.payable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const mapped = orderData.flatMap((order) => {
            const orderReceivables = receivables.results.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
            const orderPayables = payables.results.filter((payable) => payable.invoiceDetails.orderID === order.id);
            return {
                ...order,
                receivables: orderReceivables,
                bills: orderPayables,
            };
        });

        return mapped as (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            bills: Invoice.PayableTransport[];
        })[];
    };

    const calculateActualOrderProfit = (order: AllianceOrderWithReceivables) => {
        let invoicesTotal = 0;
        order.receivables.forEach((invoice: Invoice.ReceivableTransport) => {
            invoice.invoiceDetails.lineItems.forEach((lineItem: Invoice.LineItemTransport) => {
                // if (lineItem.itemName === 'CC Fee') hasCCFee = true;
                const lineItemTotal = lineItem?.totalPrice ? centsToDollars(lineItem.totalPrice) : 0;
                invoicesTotal = invoicesTotal + lineItemTotal;
            });
        });
        return round(invoicesTotal);
    };

    const handleGoogleClicksUpload = async (data: any) => {
        const googleClickUploadData = [];
        const googleCallUploadData = [];
        const bingClickUploadData = [];
        const bingCallUploadData = [];
        for (const item of data) {
            let clid = null;
            let leadPhoneNumber = null;
            if (item.gds_SOURCE === 'Google Ads') {
                if (item.gds_parsed_gclid && item.gds_parsed_gclid !== 'null') {
                    clid = item.gds_parsed_gclid;
                } else {
                    const gclidRegex = /.gclid=([^&]*)/;
                    if (gclidRegex.test(item.landing_page_url)) {
                        clid = item.landing_page_url.match(gclidRegex)[1];
                    } else if (gclidRegex.test(item.last_requested_url)) {
                        clid = item.last_requested_url.match(gclidRegex)[1];
                    } else {
                        // if neither fetch the phone number
                        const leadResponse = await client.customer().adminPortal.leads.fetch(item.id);
                        leadPhoneNumber = leadResponse?.phoneNumber;
                    }
                }
            }
            if (item.gds_SOURCE === 'Bing Ads') {
                if (item.gds_parsed_msclkid && item.gds_parsed_msclkid !== 'null') {
                    clid = item.gds_parsed_msclkid;
                } else {
                    const msclkidRegex = /.msclkid=([^&]*)/;
                    if (msclkidRegex.test(item.landing_page_url)) {
                        clid = item.landing_page_url.match(msclkidRegex)[1];
                    } else if (msclkidRegex.test(item.last_requested_url)) {
                        clid = item.last_requested_url.match(msclkidRegex)[1];
                    } else {
                        // if neither fetch the phone number
                        const leadResponse = await client.customer().adminPortal.leads.fetch(item.id);
                        leadPhoneNumber = leadResponse?.phoneNumber;
                    }
                }
            }

            let conversionValue = 0;
            const orderResponse = await client.order().adminPortal.query({
                allianceCustomerID: item.customer_id,
            });

            const ordersData = await getOrdersWithInvoice(orderResponse.results);
            if (ordersData.every((order: any) => order.status === 'CANCELLED')) {
                // Do nothing
            }
            // if (
            // ordersData.every(
            //     (order: AllianceOrderWithReceivables) => order.status === 'CANCELLED',
            // || order.status === 'COMPLETED'
            // )
            // )
            else {
                ordersData.forEach((order: AllianceOrderWithReceivables) => {
                    // if (order.status === 'COMPLETED') {
                    conversionValue = conversionValue + calculateActualOrderProfit(order);
                    // }
                });
                // } else if (isBefore(new Date(item.lead_date), subDays(new Date(), 83))) {
                //     // TODO add in 7 days from 90 days
                //     ordersData.forEach((order: AllianceOrderWithReceivables) => {
                //         if (order.status !== 'CANCELLED') {
                //             conversionValue = conversionValue + 50;
                //         } else {
                //             conversionValue = conversionValue + calculateActualOrderProfit(order);
                //         }
                //         // if (order.status === 'COMPLETED') {
                //         //     conversionValue = conversionValue + calculateActualOrderProfit(order);
                //         // } else if (order.status !== 'CANCELLED') {
                //         //     conversionValue = conversionValue + 50;
                //         // }
                //     });
            }
            // else if (isBefore(new Date(item.lead_date), subDays(new Date(), 83))) {
            //     // TODO add in 7 days from 90 days
            //     ordersData.forEach((order: AllianceOrderWithReceivables) => {
            //         if (order.status !== 'CANCELLED') {
            //             conversionValue = conversionValue + 50;
            //         } else {
            //             conversionValue = conversionValue + calculateActualOrderProfit(order);
            //         }
            //         // if (order.status === 'COMPLETED') {
            //         //     conversionValue = conversionValue + calculateActualOrderProfit(order);
            //         // } else if (order.status !== 'CANCELLED') {
            //         //     conversionValue = conversionValue + 50;
            //         // }
            //     });
            // }

            const conversionName = clid ? 'Offline Sales - Clicks' : 'Offline Sales - Calls';
            if (conversionValue !== 0) {
                const uploadItem = {
                    'Conversion Name': conversionName,
                    'Conversion Time': format(new Date(item.order_date), 'M/d/yyyy H:mm:ss'),
                    'Conversion Value': conversionValue.toFixed(2),
                    'Conversion Currency': 'USD',
                };
                if (item.gds_SOURCE === 'Google Ads' && clid && clid !== 'null') {
                    googleClickUploadData.push({
                        'Google Click ID': clid,
                        ...uploadItem,
                    });
                } else if (item.gds_SOURCE === 'Google Ads' && (!clid || clid === 'null')) {
                    googleCallUploadData.push({
                        "Caller's Phone Number": leadPhoneNumber,
                        'Call Start Time':
                            item.start_time && item.start_time !== 'null'
                                ? format(new Date(item.start_time), 'M/d/yyyy H:mm:ss')
                                : undefined,
                        ...uploadItem,
                    });
                } else if (item.gds_SOURCE === 'Bing Ads' && clid && clid !== 'null') {
                    bingClickUploadData.push({
                        'Microsoft Click ID': clid,
                        ...uploadItem,
                    });
                } else if (item.gds_SOURCE === 'Bing Ads' && (!clid || clid === 'null')) {
                    bingCallUploadData.push({
                        "Caller's Phone Number": leadPhoneNumber,
                        'Call Start Time':
                            item.start_time && item.start_time !== 'null'
                                ? format(new Date(item.start_time), 'M/d/yyyy H:mm:ss')
                                : undefined,
                        ...uploadItem,
                    });
                }
            }
        }

        setGoogleClickData(googleClickUploadData);
        setGoogleCallData(googleCallUploadData);
        setMicrosoftClickData(bingClickUploadData);
        setMicrosoftCallData(bingCallUploadData);
        setIsLoading(false);
    };

    const handleExcelUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const file = event?.target?.files?.[0];
        if (!file) return;
        const filereader = new FileReader();
        filereader.readAsArrayBuffer(file);
        filereader.onload = (e) => {
            const bufferArray = e?.target?.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer', raw: true });
            const wsname = wb.SheetNames[0];
            // const wsname1 = wb.SheetNames[1];
            const ws = wb.Sheets[wsname];
            // const ws1 = wb.Sheets[wsname1];
            const data = XLSX.utils.sheet_to_json(ws);
            const formattedData = data.map((item: any) => {
                const orderDate = item.order_date;
                const amPM = orderDate.slice(-2);
                let newOrderDate = orderDate.slice(0, -5);
                newOrderDate = newOrderDate + ' ' + amPM;
                let newStartTime = item.start_time;
                if (newStartTime !== 'null') {
                    const amPM2 = newStartTime.slice(-2);
                    newStartTime = newStartTime.slice(0, -5);
                    newStartTime = newStartTime + ' ' + amPM2;
                }
                return {
                    ...item,
                    order_date: newOrderDate,
                    start_time: newStartTime,
                };
            });
            handleGoogleClicksUpload(formattedData);
        };
    };

    const handleAssociateLeadsToCustomers = async (rawStart: Date | '') => {
        if (!rawStart) return;
        const start = new Date(new Date(rawStart).setHours(0, 0, 0, 0)).toISOString();
        setIsLoading(true);
        const updatedList = [];
        const customers = await client.customer().adminPortal.query({ createdAt_gte: start });
        const customersData = customers;
        if (!customersData) return;
        for (const customer of customersData) {
            for (const contact of customer.contacts) {
                if (contact.email) {
                    const leadRes = await client.customer().adminPortal.leads.query({
                        email: contact.email,
                    });

                    if (leadRes.results?.length > 0) {
                        for (const lead of leadRes.results) {
                            if (!lead.allianceCustomer?.id) {
                                await client.customer().adminPortal.leads.update(lead.id, {
                                    allianceCustomerID: customer.id,
                                });
                                updatedList.push({ leadID: lead.id, customerID: customer.id });
                            }
                        }
                    }
                }
                if (contact.phoneNumber) {
                    const leadRes = await client.customer().adminPortal.leads.query({
                        phoneNumber: contact.phoneNumber,
                    });
                    if (leadRes.results?.length > 0) {
                        for (const lead of leadRes.results) {
                            if (!lead.allianceCustomer?.id) {
                                client.customer().adminPortal.leads.update(lead.id, {
                                    allianceCustomerID: customer.id,
                                });
                                updatedList.push({ leadID: lead.id, customerID: customer.id });
                            }
                        }
                    }
                }
            }
        }
        setIsLoading(false);
        alert('Association Finished');
    };

    return (
        <div className="container mx-auto flex flex-col gap-6 p-6">
            <h1 className="text-xl">Associate Leads to Customers</h1>
            <div className="flex items-center gap-4">
                <div className="mb-0 mr-10 max-w-xs">
                    <DatePicker label="Created After" value={startDate} onChange={(value) => setStartDate(value)} />
                </div>
                <div>
                    <button
                        disabled={!startDate || isLoading}
                        onClick={() => handleAssociateLeadsToCustomers(startDate)}
                        className="btn-secondary-text-only"
                    >
                        Associate
                    </button>
                </div>
            </div>

            <h2 className="text-lg">Prepare PPC Offline Conversion Upload</h2>

            <div>
                <button className="btn-primary relative" disabled={isLoading}>
                    Import GDS Data
                    <input
                        className="blankFileInput"
                        type="file"
                        onChange={(event) => handleExcelUpload(event)}
                        disabled={isLoading}
                        accept={inputAccept}
                    />
                </button>
            </div>

            <div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        <div className="p-3">
                            <CSVLink filename="google-click-data" data={googleClickData} className="btn-primary-text-only">
                                Download Google Click Data
                            </CSVLink>
                        </div>
                        <div className="p-3">
                            <CSVLink filename="google-call-data" data={googleCallData} className="btn-primary-text-only">
                                Download Google Call Data
                            </CSVLink>
                        </div>
                        <div className="p-3">
                            <CSVLink filename="microsoft-click-data" data={microsoftClickData} className="btn-primary-text-only">
                                Download Microsoft Click Data
                            </CSVLink>
                        </div>
                        <div className="p-3">
                            <CSVLink filename="microsoft-call-data" data={microsoftCallData} className="btn-primary-text-only">
                                Download Microsoft Call Data
                            </CSVLink>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MarketingReports;
