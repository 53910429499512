import { useEffect } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { Button, CurrencyTextField, DatePicker, Dialog, Select, SelectOption, TextField } from '@wayste/sour-ui';
import { Controller, useForm } from 'react-hook-form';
import { useFlash } from '../../../hooks/useFlash';
import { everyPaymentMethods, paymentMethodsEnums } from '../../../utils/shared-types';

type PaymentUpdateModalProps = {
    open: boolean;
    onCancel: () => void;
    payment?: Invoice.PaymentTransport;
    invoice?: Invoice.InvoiceTransport;
    screen: 'payable' | 'receivable';
};

export const PaymentUpdateModal = ({ open, onCancel, payment, screen, invoice }: PaymentUpdateModalProps) => {
    const {
        register,
        control,
        formState: { errors, isLoading },
        handleSubmit,
        reset,
    } = useForm<Invoice.PaymentUpdateTransport>({});

    const client = useWaysteClient();
    const { showFlash } = useFlash();

    useEffect(() => {
        if (payment) {
            reset({
                paymentSentDate: payment.paymentSentDate || payment.date,
                paymentReceivedDate: payment.paymentReceivedDate || payment.date,
                paymentIdentifier: payment.paymentIdentifier,
                amount: payment.amount,
                paymentMethod: payment.paymentMethod,
            });
        }
    }, [payment]);

    const handleSave = async (data: Invoice.PaymentUpdateTransport) => {
        try {
            const invoiceID = invoice?.id || payment?.invoiceID;
            if (!invoiceID || !payment?.id) {
                throw new Error('Invoice ID is required');
            }
            console.log(data);
            await client.invoice().adminPortal.payment.update(invoiceID, payment?.id, data);
            showFlash('Payment updated successfully', 'success');
            onCancel();
        } catch (error) {
            console.error(error);
            showFlash((error as Error)?.message || 'There was an error updating payment.', 'warning');
        }
    };

    return (
        <Dialog open={open} onClose={onCancel} styledTitle="Payment Update">
            <div className="flex w-full flex-col gap-2">
                <div className="w-full">
                    <Controller
                        control={control}
                        name={screen === 'payable' ? 'paymentSentDate' : 'paymentReceivedDate'}
                        render={({ field }) => (
                            <DatePicker label="Payment date" value={field.value ? new Date(field.value) : ''} onChange={field.onChange} />
                        )}
                    />
                </div>
                <div className="w-full">
                    <Controller
                        control={control}
                        name={'paymentMethod'}
                        render={({ field }) => (
                            <Select value={field.value} label="Payment Method" onSelect={field.onChange}>
                                {Object.keys(paymentMethodsEnums).map((item) => (
                                    <SelectOption key={item} value={item}>
                                        {everyPaymentMethods[item as keyof typeof everyPaymentMethods]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-full">
                    <TextField label="Transaction ID" inputProps={{ ...register('paymentIdentifier') }} />
                </div>
                <div className="w-full">
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Amount is required',
                            },
                        }}
                        render={({ field }) => (
                            <CurrencyTextField
                                label="Payment amount"
                                value={field.value || ''}
                                useCents
                                onChange={(value) => field.onChange(value)}
                                error={errors.amount}
                                numericFormatProps={{ decimalScale: 5 }}
                            />
                        )}
                    />
                </div>
                <p className="text-sm text-gray-500">
                    Do not change credit card charges to another payment method. If they are being refunded it <b>MUST</b> be refunded and
                    then enterer again.
                </p>

                <div className="flex justify-end gap-2">
                    <Button onClick={onCancel} className="btn-secondary">
                        Cancel
                    </Button>

                    <Button onClick={handleSubmit(handleSave)} className="btn-primary" loading={isLoading}>
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};
