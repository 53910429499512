import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { TanDataGrid } from '@wayste/sour-ui';
import { formatISODateString, moneyFormatter } from '@wayste/utils';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../contexts';
import { GridCellStatus } from '../../pages/billing';
import { paymentMethods, paymentTerms, routes } from '../../utils';
import DetailsCardWrapper from '../DetailsCardWrapper';

type InvoiceRow = {
    invoiceNumber: string;
    servicePeriod: string;
    total: number;
    paid: string;
    term: string;
    method: string;
    autoPay: string;
    issueDate: string;
    dueDate: string;
    status: string;
    actions: Invoice.InvoiceTransport;
};

interface InvoiceTableProps {
    externalReceivables?: Invoice.ReceivableTransport[];
    receivableQuery?: Invoice.GetReceivablesQuery;
    serviceGroupings?: UniversalService.ServiceGrouping[];
    suppressQuery?: boolean;
}

export const ReceivableTableCard = ({ externalReceivables, receivableQuery, suppressQuery, serviceGroupings }: InvoiceTableProps) => {
    const client = useWaysteClient();
    const history = useHistory();
    const { showFlash } = useContext(UIContext);
    const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>(externalReceivables ?? []);
    const [loading, setLoading] = useState<boolean>(false);
    const allPaid = receivables.every((receivable) => receivable.invoiceDetails.paidInFull);

    useEffect(() => {
        if (!loading && receivableQuery && !suppressQuery) fetchReceivables(receivableQuery);
    }, [receivableQuery, suppressQuery]);

    const fetchReceivables = async (receivableQuery: Invoice.GetReceivablesQuery) => {
        setLoading(true);
        try {
            const receivables = await client.invoice().adminPortal.receivable.query({
                ...receivableQuery,
                limit: 10000,
            });
            setReceivables(receivables.results);
            setData(receivables.results.map(getInvoiceFromServiceEvent));
        } catch (error) {
            // console.error(error);
            showFlash('Error Fetching Receivables', 'warning');
        } finally {
            setLoading(false);
        }
    };

    // TODO: Fill AutoPay
    const getInvoiceFromServiceEvent = (receivable: Invoice.ReceivableTransport) => {
        let servicePeriod = 'N/A';
        const serviceGrouping = serviceGroupings?.find(
            (serviceGrouping) => serviceGrouping.id === receivable.invoiceDetails.serviceGroupingID,
        );
        if (!serviceGrouping) {
            servicePeriod = 'Unknown';
        }

        if (serviceGrouping && receivable.invoiceDetails.lineItems.some((lineItem) => lineItem.serviceOrderID)) {
            // get all the service order ids
            const serviceOrderIDs = Array.from(new Set(receivable.invoiceDetails.lineItems.map((lineItem) => lineItem.serviceOrderID)));

            if (serviceOrderIDs.length === 1) {
                const serviceOrder = serviceGrouping?.serviceOrders.find((serviceOrder) => serviceOrder.id === serviceOrderIDs[0]);
                if (serviceOrder) {
                    servicePeriod = `${formatISODateString(serviceOrder.startDate?.toString())} - ${
                        serviceOrder.endDate ? formatISODateString(serviceOrder.endDate?.toString()) : 'Ongoing'
                    }`;
                }
            } else {
                // now find the earliest and latest service order dates where null is the latest for end dates
                const serviceOrders = serviceOrderIDs.map((id) =>
                    serviceGrouping?.serviceOrders.find((serviceOrder) => serviceOrder.id === id),
                );

                const earliestServiceOrder = serviceOrders.reduce((prev, curr) => {
                    if (!curr) return prev;
                    if (!prev) return curr;
                    if (!curr.startDate) return prev;
                    if (!prev.startDate) return curr;
                    if (curr.startDate < prev.startDate) return curr;
                    return prev;
                });

                const latestServiceOrder = serviceOrders.reduce((prev, curr) => {
                    if (!curr) return prev;
                    if (!prev) return curr;
                    if (!curr.endDate) return prev;
                    if (!prev.endDate) return curr;
                    if (curr.endDate > prev.endDate) return curr;
                    return prev;
                });
                servicePeriod = `${formatISODateString(earliestServiceOrder?.startDate)} - ${
                    latestServiceOrder?.endDate ? formatISODateString(latestServiceOrder?.endDate) : 'Ongoing'
                }`;
            }
        }

        const newInvoice: InvoiceRow = {
            invoiceNumber: serviceGrouping?.orderNumber + '-' + receivable.invoiceDetails.invoiceNumber ?? '',
            status: receivable.invoiceDetails.status,
            servicePeriod,
            total: receivable.invoiceDetails.total,
            paid: receivable.invoiceDetails.paidInFull ? 'Yes' : 'No',
            term: serviceGrouping?.paymentTerm ?? '',
            method: serviceGrouping?.paymentMethod ?? '',
            autoPay: '',
            issueDate: receivable.invoiceDetails.issueDate?.slice(0, 8) ?? '',
            dueDate: receivable.invoiceDetails.dueDate?.slice(0, 8) ?? '',
            actions: receivable.invoiceDetails,
        };
        return newInvoice;
    };

    const [data, setData] = useState<InvoiceRow[]>(receivables.map(getInvoiceFromServiceEvent));

    const columnHelper = createColumnHelper<InvoiceRow>();
    const columns: ColumnDef<InvoiceRow, any>[] = [
        columnHelper.accessor('invoiceNumber', {
            header: 'Invoice Number',
            cell: (info) => <div className="min-w-fit">{info.getValue()}</div>,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => (
                <div className="w-20">
                    <GridCellStatus value={info.getValue() as Invoice.InvoiceStatus} />
                </div>
            ),
        }),
        columnHelper.accessor('servicePeriod', {
            header: 'Service Period',
            cell: (info) => <div className="min-w-fit whitespace-nowrap">{info.getValue()}</div>,
        }),
        columnHelper.accessor('total', {
            header: 'Total',
            cell: (info) => <div className="min-w-fit">{moneyFormatter(info.getValue())}</div>,
        }),
        columnHelper.accessor('paid', {
            header: 'Paid',
            cell: (info) => <div className="min-w-fit">{info.getValue()}</div>,
        }),
        columnHelper.accessor('term', {
            header: 'Term',
            cell: (info) => <div className="w-20">{paymentTerms[info.getValue() as keyof typeof paymentTerms]}</div>,
        }),
        columnHelper.accessor('method', {
            header: 'Method',
            cell: (info) => <div className="w-40">{paymentMethods[info.getValue() as keyof typeof paymentMethods]}</div>,
        }),
        columnHelper.accessor('autoPay', {
            header: 'Auto Pay',
            cell: (info) => <div className="min-w-fit">{info.getValue()}</div>,
        }),
        columnHelper.accessor('issueDate', {
            header: 'Issue Date',
            cell: (info) => <div className="min-w-fit">{formatISODateString(info.getValue(), 'MM/dd/yyyy', '')}</div>,
        }),
        columnHelper.accessor('dueDate', {
            header: 'Due Date',
            cell: (info) => <div className="min-w-fit">{formatISODateString(info.getValue(), 'MM/dd/yyyy', '')}</div>,
        }),
        columnHelper.accessor('actions', {
            header: '',
            cell: (info) => (
                <div>
                    <ArrowTopRightOnSquareIcon
                        className="text-wayste-blue-400 mr-4 size-5 cursor-pointer"
                        onClick={() => {
                            history.push(
                                routes.billingProduct.details(
                                    info.getValue().serviceGroupingID,
                                    info.getValue().lineItems[0].serviceOrderID,
                                ),
                            );
                        }}
                    />
                </div>
            ),
        }),
    ];

    return (
        <DetailsCardWrapper heading="Invoices" subheading="All Invoices Paid" subheadingStatus={loading ? '' : allPaid ? 'Yes' : 'No'}>
            <TanDataGrid data={data} columns={columns} loading={loading} className="-mx-5 -mb-5 -mt-4" />
        </DetailsCardWrapper>
    );
};

export default ReceivableTableCard;
