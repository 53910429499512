import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from '@wayste/sour-ui';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import Loading from '../Loading';

const Marker = ({ children }: any) => children;

const OrdersHeatMap = ({ onGetOrders }: any) => {
    const mapRef = useRef();
    const [zoom, setZoom] = useState(10);
    const [bounds, setBounds] = useState<any>(null);
    const [orders, setOrders] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<'' | Date>('');
    const [endDate, setEndDate] = useState<'' | Date>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (startDate && endDate) {
            setIsLoading(true);
            onGetOrders(startDate, endDate)
                .then((orders: any) => {
                    const filtered = orders.filter((order: any) => order.serviceLocation.coordinates);
                    setOrders(filtered);
                    setIsLoading(false);
                })
                .catch((error: any) => {
                    console.warn(error);
                    alert('An error occurred, open the console and send a picture to Joe');
                    setOrders([]);
                    setIsLoading(false);
                });
        }
    }, [startDate, endDate]);

    //get clusters
    const points = orders.map((order) => ({
        type: 'Feature',
        properties: {
            cluster: false,
        },
        geometry: {
            type: 'Point',
            coordinates: [order.serviceLocation.coordinates.lng, order.serviceLocation.coordinates.lat],
        },
    }));

    const { clusters } = useSupercluster({
        points,
        zoom,
        bounds,
        options: { radius: 80, maxZoom: 20 },
    });

    const map = (
        <GoogleMapReact
            bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY }}
            defaultCenter={{ lat: 40.4153791, lng: -74.1586838 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
            }}
            onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
            }}
        >
            {clusters.map((cluster) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;
                if (isCluster) {
                    return (
                        <Marker lat={latitude} lng={longitude} key={cluster.id}>
                            <div
                                className="text-sourgum-greyblue-900"
                                style={{
                                    width: `${28 + (pointCount / points.length) * 10}px`,
                                    height: `${28 + (pointCount / points.length) * 10}px`,
                                    color: '#FFFFFF',
                                    borderRadius: '50%',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: 15,
                                }}
                            >
                                {pointCount}
                            </div>
                        </Marker>
                    );
                }
                return (
                    <Marker lat={latitude} lng={longitude} key={Math.random()}>
                        <div
                            className="bg-sourgum-green-400"
                            style={{
                                height: 15,
                                width: 15,
                                borderRadius: '50%',
                                border: 'solid 2px #FFFFFF',
                            }}
                        />
                    </Marker>
                );
            })}
        </GoogleMapReact>
    );

    if (isLoading) return <Loading />;

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ padding: '0px 15px 15px' }}>
                <div style={{ marginRight: 40, marginBottom: 0, maxWidth: 200 }}>
                    <DatePicker label="Start date" value={startDate} onChange={(value) => setStartDate(value)} />
                </div>
                <div style={{ marginBottom: 0, maxWidth: 200 }}>
                    <DatePicker label="End date" value={endDate} onChange={(value) => setEndDate(value)} />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {!startDate || !endDate ? (
                    <h6 className="text-xl font-medium">Select a Start and End Date to Generate a Report</h6>
                ) : orders.length === 0 ? (
                    <h6 className="text-xl font-medium">No Orders in This Date Range</h6>
                ) : (
                    map
                )}
            </div>
        </div>
    );
};

export default OrdersHeatMap;
