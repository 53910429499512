import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import ReceivableTableCard from '../../../components/ReceivableTableCard/ReceivableTableCard';
import { UIContext } from '../../../contexts';
import ServiceGroupingTableCard from './ServiceGroupingTableCard';

const UniversalServicesDetails = ({ customerID }: { customerID: string }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serviceGroupings, setServiceGroupings] = useState<UniversalService.ServiceGrouping[]>([]);
    const [query, setQuery] = useState<Invoice.GetReceivablesQuery>({});
    const suppressQuery = Object.keys(query).length === 0;

    const { showFlash } = useContext(UIContext);
    const client = useWaysteClient();

    useEffect(() => {
        fetchServiceGroupings();
    }, []);

    const fetchServiceGroupings = async () => {
        setIsLoading(true);
        try {
            const serviceGroupings = await client.universalService().serviceGrouping.query({
                customerID: customerID,
            });
            setServiceGroupings(serviceGroupings.results);

            if (serviceGroupings.results.length) {
                const serviceOrderIDs = serviceGroupings.results
                    .map((serviceGrouping) => serviceGrouping.serviceOrders.map((serviceOrder) => serviceOrder.id).flat())
                    .flat();
                const invoiceGroupings = await client.universalService().invoice.queryByServiceOrderId(serviceOrderIDs);
                console.log('invoiceGroupings: ', invoiceGroupings);
                setQuery({
                    productInvoiceGroupingID: invoiceGroupings.slice(1, 50).join(','),
                });
            }
        } catch (error) {
            console.error(error);
            showFlash('Error Fetching Service Groupings', 'warning');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="mb-6 mt-10 border-b text-2xl">Universal Services</div>
            <ServiceGroupingTableCard serviceGroupings={serviceGroupings} isLoading={isLoading} />
            <ReceivableTableCard serviceGroupings={serviceGroupings} suppressQuery={suppressQuery} receivableQuery={query} />
        </>
    );
};
export default UniversalServicesDetails;
