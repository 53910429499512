import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils';

const Settings = () => {
    const history = useHistory();

    const handleQuickBooksMappingClick = () => {
        history.push(routes.accountSettings.quickBooksMapping);
    };

    const handleGoogleAdsSettingsClick = () => {
        history.push(routes.accountSettings.googleAds);
    };

    const navigateToUniversalServices = () => {
        history.push(routes.universalServices.services.list);
    };

    const navigateToManageTags = () => {
        history.push(routes.accountSettings.tagManagement);
    };

    return (
        <div className="container mx-auto flex flex-col gap-4 px-3 py-7">
            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Billing Settings</h2>
                    <button className="btn-primary" onClick={handleQuickBooksMappingClick}>
                        Edit QuickBooks Mapping
                    </button>
                </div>
            </div>

            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Google Ads Settings</h2>
                    <button className="btn-primary" onClick={handleGoogleAdsSettingsClick}>
                        Update Google Ads Settings
                    </button>
                </div>
            </div>

            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Service Families</h2>
                    <button className="btn-primary" onClick={navigateToUniversalServices}>
                        Update Service Families
                    </button>
                </div>
            </div>

            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Internal Ticket Tags</h2>
                    <button className="btn-primary" onClick={navigateToManageTags}>
                        Update Internal Ticket Tags
                    </button>
                </div>
            </div>
            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Coupons</h2>
                    <button className="btn-primary" onClick={() => history.push(routes.accountSettings.coupons)}>
                        Update Coupons
                    </button>
                </div>
            </div>
            <div className="rounded bg-white p-5 drop-shadow">
                <div className="flex items-center justify-between">
                    <h2 className="text-xl font-medium">Users</h2>
                    <button className="btn-primary" onClick={() => history.push(routes.accountSettings.users)}>
                        Manage Users
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settings;
