import { Button, Popover } from '@wayste/sour-ui';
import { getPrimaryContact, moneyFormatter } from '@wayste/utils';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { haulerBillPaymentMethods, paymentTerms, routes } from '../../utils';
import StarRating from '../StarRating';
import { VendorPricingRow } from './SpreadsTable';

interface VendorTooltipProps {
    haulerPricing: VendorPricingRow;
    onRowClick?: (haulerPricing: VendorPricingRow) => void;
}

const VendorTooltip = ({ haulerPricing, onRowClick }: VendorTooltipProps) => {
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Popover
            text={
                <div>
                    <div className="whitespace-pre-line">Notes: {`${haulerPricing.notes} ${haulerPricing.hauler.notes}`}</div>
                    <div>Open Sat: {haulerPricing.hauler?.hoursOfOperation?.sat?.start ? 'Yes' : 'No'}</div>
                    <div>Sizes: {haulerPricing?.rowRates?.materialData?.sizes?.map((size) => `${size.size}, `)}</div>
                    <div>
                        Rental Period: {haulerPricing?.hauler?.defaultRentPeriod?.value} days -{' '}
                        {moneyFormatter(haulerPricing?.hauler?.rentExtensionFee, {
                            undefinedBehavior: 'Unknown',
                        })}{' '}
                        / day
                    </div>
                    <div>Payment Term: {paymentTerms[haulerPricing?.hauler.defaultPaymentTerm as keyof typeof paymentTerms] || ''}</div>
                    <div>
                        Payment Method:{' '}
                        {haulerBillPaymentMethods[haulerPricing?.hauler.defaultPaymentMethod as keyof typeof haulerBillPaymentMethods]}
                    </div>
                    <div className="-mx-2 mt-1 flex min-w-[150px] flex-col bg-white text-sm text-black text-opacity-90">
                        <Button
                            tabIndex={-1}
                            className="btn-primary-text-only py-1 text-sm"
                            disabled={!getPrimaryContact(haulerPricing.hauler)?.phoneNumber}
                            onClick={() => copyToClipboard(getPrimaryContact(haulerPricing.hauler)?.phoneNumber || '')}
                            endIcon={<DocumentDuplicateIcon className="h-3.5 w-3.5" />}
                        >
                            {getPrimaryContact(haulerPricing.hauler)?.phoneNumber}
                        </Button>
                        <Button
                            tabIndex={-1}
                            className="btn-primary-text-only py-1 text-sm"
                            disabled={!getPrimaryContact(haulerPricing.hauler)?.email}
                            onClick={() => copyToClipboard(getPrimaryContact(haulerPricing.hauler)?.email || '')}
                            endIcon={<DocumentDuplicateIcon className="h-3.5 w-3.5" />}
                        >
                            <span className="lowercase">{getPrimaryContact(haulerPricing.hauler)?.email}</span>
                        </Button>
                    </div>
                </div>
            }
        >
            <span
                onClick={() => {
                    if (!onRowClick) {
                        if (haulerPricing.haulerID) window.open(routes.haulers.details(haulerPricing.haulerID));
                    }
                }}
            >
                {haulerPricing.haulerName} <StarRating rating={haulerPricing?.hauler?.rating} fontSize="small" />
            </span>
        </Popover>
    );
};

export default VendorTooltip;
