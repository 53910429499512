import React from 'react';
import { Checkbox, Dialog } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { format } from 'date-fns';
import { routes } from '../../utils';
import type { FEObject } from './PayablesMakePayment';

type PayableBreakdownDialogProps = {
    open: boolean;
    onClose: () => void;
    feObject: FEObject | null;
    onCheckboxClick: (id: string, checked: boolean) => void;
    onSelectDeselectAllClick: (action: 'selectAll' | 'deselectAll') => void;
};

const PayableBreakdownDialog = ({ open, onClose, feObject, onCheckboxClick, onSelectDeselectAllClick }: PayableBreakdownDialogProps) => {
    const onOrderNumberClick = (orderID?: string) => {
        if (!orderID) return;
        window.open(window.location.origin + routes.billing.details(orderID), '_blank');
    };

    const handleSelectDeselectAllClick = () => {
        if (feObject?.payables?.length === feObject?.feOnlyPayablesBeingPaid?.length) {
            onSelectDeselectAllClick('deselectAll');
        } else {
            onSelectDeselectAllClick('selectAll');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} showX styledTitle={feObject?.vendorName} variant="underlined-header" className="max-w-4xl">
            <div className="grid grid-cols-5 gap-4">
                <div className="col-span-5">
                    <button className="btn-secondary-text-only" type="button" onClick={handleSelectDeselectAllClick}>
                        {feObject?.payables?.length === feObject?.feOnlyPayablesBeingPaid?.length ? 'Deselect All' : 'Select All'}
                    </button>
                </div>
                <div>Pay</div>
                <div>Date Created</div>
                <div>Inv Number</div>
                <div>Amount</div>
                <div>SAP Order</div>
                {feObject?.payables
                    .sort((a, b) => ((a.invoiceDetails.metadata?.createdAt || 0) > (b.invoiceDetails.metadata?.createdAt || 0) ? 1 : -1))
                    .map((payable) => (
                        <React.Fragment key={payable.id}>
                            <div>
                                <Checkbox
                                    inputProps={{
                                        checked: Boolean(feObject.feOnlyPayablesBeingPaid.find((item) => item.id === payable.id)),
                                        onChange: (e) => onCheckboxClick(payable.id, e.target.checked),
                                    }}
                                />
                            </div>
                            <div>
                                {payable.invoiceDetails.metadata?.createdAt
                                    ? format(new Date(payable.invoiceDetails.metadata.createdAt), 'MM/dd/yy')
                                    : null}
                            </div>
                            <div>{payable.invoiceDetails.invoiceNumber}</div>
                            <div>{moneyFormatter(payable.invoiceDetails.remainingBalance)}</div>
                            <div
                                className="text-primary-400 cursor-pointer underline"
                                onClick={() => onOrderNumberClick(payable.invoiceDetails.orderID)}
                            >
                                {payable.invoiceDetails.orderNumber}
                            </div>
                        </React.Fragment>
                    ))}
            </div>
        </Dialog>
    );
};

export default PayableBreakdownDialog;
